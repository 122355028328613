export var orderStatus = {
  Pending: 'Pending',
  Paid: 'Paid',
  Rejected: 'Rejected'
};
export var giftCodeStatuses = {
  Unpaid: 'Unpaid',
  Redeemed: 'Redeemed',
  NotRedeemed: 'NotRedeemed'
};
export var orderItemTypes = {
  Package: 'package',
  Subscription: 'subscription'
};