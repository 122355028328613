export var transferPanelType = {
  PAYMENT: 'payment',
  TRANSFER: 'transfer'
};
export var getTransferStatusDisplayName = function getTransferStatusDisplayName(status) {
  var displayName = null;
  switch (status) {
    case transferStatuses.NONE:
      displayName = 'Open for Deployment';
      break;
    case transferStatuses.PAID:
      displayName = 'Deployment Paid';
      break;
    case transferStatuses.PENDING:
      displayName = 'Deployment In Progress';
      break;
    case transferStatuses.TRANSFERRED:
      displayName = 'Deployment Complete';
      break;
    case transferStatuses.INITIATED:
      displayName = 'Deployment Initiated';
      break;
    case transferStatuses.ERROR:
      displayName = 'Deployment error';
      break;
    case transferStatuses.INITIATED_RETRY:
      displayName = 'Deployment Payment Failed';
      break;
    default:
      break;
  }
  return displayName;
};
export var transferStatuses = {
  NONE: 'none',
  INITIATED: 'transferInitiated',
  INITIATED_RETRY: 'transferInitiationRetriable',
  PAID: 'transferPaid',
  PENDING: 'transferInProgress',
  TRANSFERRED: 'transferred',
  ERROR: 'transferError'
};
export var pendingWindowStatus = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail'
};