function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { sub } from 'exact-math';
import starterLogo from '../../assets/images/bonuses/starter.png';
import { packageTypes } from '../../enums/packages';
export var packageActionTypes = {
  SetCurrent: '[Set] Current package',
  Fulfill: '[Fulfill] Initialize packages',
  FulfillAll: '[FulfillAll] Initialize all packages',
  UpdateAll: '[UpdateAll] Update all packages',
  Delete: '[Delete] Erase packages',
  Add: '[Add] Add package'
};
var initialState = {
  current: {},
  items: [],
  allPackages: [],
  allGasPackages: []
};
var findCurrentPackage = function findCurrentPackage(packages) {
  var _sort$shift;
  return (_sort$shift = _toConsumableArray(packages).sort(function (a, b) {
    var _b$price, _a$price;
    return sub((_b$price = b === null || b === void 0 ? void 0 : b.price) !== null && _b$price !== void 0 ? _b$price : 0, (_a$price = a === null || a === void 0 ? void 0 : a.price) !== null && _a$price !== void 0 ? _a$price : 0);
  }).shift()) !== null && _sort$shift !== void 0 ? _sort$shift : {};
};
var getPackagesByType = function getPackagesByType(packageItems) {
  var allPackages = [];
  var allGasPackages = [];
  (packageItems || []).forEach(function (el) {
    var _ref, _el$picThumbnailUrl;
    var item = _objectSpread(_objectSpread({}, el), {}, {
      logo: (_ref = (_el$picThumbnailUrl = el.picThumbnailUrl) !== null && _el$picThumbnailUrl !== void 0 ? _el$picThumbnailUrl : el.pictureUrl) !== null && _ref !== void 0 ? _ref : starterLogo
    });
    item.type.toLowerCase() === packageTypes.GAS_PACKAGE.toLowerCase() ? allGasPackages.push(item) : allPackages.push(item);
  });
  return {
    allPackages: allPackages,
    allGasPackages: allGasPackages
  };
};
export var packageReducer = persistReducer({
  storage: storage,
  key: 'packages'
}, function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case packageActionTypes.Fulfill:
      {
        var _action$payload$items = action.payload.items,
          items = _action$payload$items === void 0 ? [] : _action$payload$items;
        var all = items.map(function (el) {
          var _ref2, _el$picThumbnailUrl2;
          return _objectSpread(_objectSpread({}, el), {}, {
            logo: (_ref2 = (_el$picThumbnailUrl2 = el.picThumbnailUrl) !== null && _el$picThumbnailUrl2 !== void 0 ? _el$picThumbnailUrl2 : el.pictureUrl) !== null && _ref2 !== void 0 ? _ref2 : starterLogo
          });
        });
        return {
          current: findCurrentPackage(all),
          items: all,
          allPackages: state.allPackages,
          allGasPackages: state.allGasPackages
        };
      }
    case packageActionTypes.FulfillAll:
      {
        var _items = action.payload.items;
        var _getPackagesByType = getPackagesByType(_items),
          allPackages = _getPackagesByType.allPackages,
          allGasPackages = _getPackagesByType.allGasPackages;
        return {
          current: state.current,
          items: state.items,
          allPackages: allPackages,
          allGasPackages: allGasPackages
        };
      }
    case packageActionTypes.UpdateAll:
      {
        var _action$payload = action.payload,
          _items2 = _action$payload.items,
          type = _action$payload.type;
        var statePackages = type.toLowerCase() === packageTypes.GAS_PACKAGE.toLowerCase() ? _toConsumableArray(state.allPackages) : _toConsumableArray(state.allGasPackages);
        var updatedAllPackages = [].concat(_toConsumableArray(statePackages), _toConsumableArray(_items2));
        var _getPackagesByType2 = getPackagesByType(updatedAllPackages),
          _allPackages = _getPackagesByType2.allPackages,
          _allGasPackages = _getPackagesByType2.allGasPackages;
        return {
          current: state.current,
          items: state.items,
          allPackages: _allPackages,
          allGasPackages: _allGasPackages
        };
      }
    case packageActionTypes.Add:
      {
        var _ref3, _item$picThumbnailUrl;
        var _action$payload$item = action.payload.item,
          item = _action$payload$item === void 0 ? {} : _action$payload$item;
        var newPackage = _objectSpread(_objectSpread({}, item), {}, {
          logo: (_ref3 = (_item$picThumbnailUrl = item.picThumbnailUrl) !== null && _item$picThumbnailUrl !== void 0 ? _item$picThumbnailUrl : item.pictureUrl) !== null && _ref3 !== void 0 ? _ref3 : starterLogo
        });
        var _items3 = state.items.concat(newPackage);
        return {
          current: findCurrentPackage(_items3),
          items: _items3,
          allPackages: state.allPackages,
          allGasPackages: state.allGasPackages
        };
      }
    case packageActionTypes.Delete:
      {
        return initialState;
      }
    default:
      return state;
  }
});
export var packageActions = {
  fulfillPackages: function fulfillPackages(items) {
    return {
      type: packageActionTypes.Fulfill,
      payload: {
        items: items
      }
    };
  },
  updateAllPackages: function updateAllPackages(items, type) {
    return {
      type: packageActionTypes.UpdateAll,
      payload: {
        items: items,
        type: type
      }
    };
  },
  fulfillAllPackages: function fulfillAllPackages(items) {
    return {
      type: packageActionTypes.FulfillAll,
      payload: {
        items: items
      }
    };
  },
  deletePackages: function deletePackages() {
    return {
      type: packageActionTypes.Delete
    };
  },
  addPackage: function addPackage(item) {
    return {
      type: packageActionTypes.Add,
      payload: {
        item: item
      }
    };
  }
};