import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { sub } from 'exact-math';
import WalletBalanceCard from '../components/WalletBalanceCard';
import LatestTrans from '../../../components/UI/Shared/LatestTrans';
import NoWallets from '../../../components/UI/Shared/NoWallets';
import WalletGasShopPackage from '../inner/WalletGasShopPackage';
import { walletType } from '../../../enums/wallets';
import currencyByType from '../../../utils/currencyByType';
import { ReactComponent as BalanceIcn } from '../../../assets/images/layout-svg-icons/icn-wallet-freecoins.svg';
import { ReactComponent as BlockedIcn } from '../../../assets/images/layout-svg-icons/icn-wallet-inescrow.svg';
import { ReactComponent as AvailableIcn } from '../../../assets/images/layout-svg-icons/Available-Coins-Icon.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Gas = function Gas(_ref) {
  var _gasWallet$balance, _gasWallet$blockedBal;
  var gasWallet = _ref.gasWallet,
    loadLatestTransactions = _ref.loadLatestTransactions;
  useEffect(function () {
    !gasWallet.latestTransactionsLoaded && loadLatestTransactions(gasWallet.id);
  }, [gasWallet.id]);
  return ___EmotionJSX("div", {
    className: "vk-wallets-balance-wraper"
  }, ___EmotionJSX("div", {
    className: "vk-wallets-balance-header-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("div", {
    className: "vk-heading-title-question-icon-wrap"
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Gas Wallet"))), !!(gasWallet !== null && gasWallet !== void 0 && gasWallet.id) && ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-wallets-balance-header-right-wraper"
  }, ___EmotionJSX("p", {
    className: "vk-wallets-balance-header-right-label"
  }, "Balance:"), ___EmotionJSX("h3", {
    className: "vk-wallets-balance-header-right-amount"
  }, currencyByType(gasWallet.balance, walletType.GAS))))), !(gasWallet !== null && gasWallet !== void 0 && gasWallet.id) && ___EmotionJSX(NoWallets, null)), !!(gasWallet !== null && gasWallet !== void 0 && gasWallet.id) && ___EmotionJSX("div", {
    className: "wallets-blue-container"
  }, ___EmotionJSX(WalletBalanceCard, {
    title: "Total Balance",
    amount: currencyByType(gasWallet.balance, walletType.GAS),
    image: ___EmotionJSX(BalanceIcn, null)
  }), ___EmotionJSX(WalletBalanceCard, {
    title: "Available Amount",
    amount: currencyByType(sub((_gasWallet$balance = gasWallet === null || gasWallet === void 0 ? void 0 : gasWallet.balance) !== null && _gasWallet$balance !== void 0 ? _gasWallet$balance : 0, (_gasWallet$blockedBal = gasWallet === null || gasWallet === void 0 ? void 0 : gasWallet.blockedBalance) !== null && _gasWallet$blockedBal !== void 0 ? _gasWallet$blockedBal : 0), walletType.GAS),
    image: ___EmotionJSX(AvailableIcn, null)
  }), ___EmotionJSX(WalletBalanceCard, {
    title: "Blocked Amount",
    amount: currencyByType(gasWallet.blockedBalance, walletType.GAS),
    image: ___EmotionJSX(BlockedIcn, null)
  })), (gasWallet === null || gasWallet === void 0 ? void 0 : gasWallet.id) && ___EmotionJSX(Grid, {
    container: true,
    spacing: 4
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 5
  }, ___EmotionJSX(LatestTrans, {
    latestTransactions: (gasWallet === null || gasWallet === void 0 ? void 0 : gasWallet.transactions) || [],
    walletType: walletType.GAS,
    link: "/wallet/gas-transaction-history"
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 7,
    className: "gas-wallet-packages"
  }, ___EmotionJSX(WalletGasShopPackage, null))));
};
var mapProps = function mapProps(_ref2) {
  var _accounts$current;
  var accounts = _ref2.accounts;
  return {
    gasWallet: (accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.wallets.find(function (el) {
      return el.walletType.toLowerCase() === walletType.GAS;
    })) || {}
  };
};
export default connect(mapProps)(Gas);