import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _topUpStatusMap;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { dateTime } from '../TableDataComponents';
import { Table } from '../../../../components/UI/Table';
import ColorBadge from '../../../../components/UI/Shared/ColorBadge';
import TableFooterBar from '../../../../components/UI/Shared/TableFooterBar';
import { walletType, topUpsStatus } from '../../../../enums/wallets';
import currencyByType from '../../../../utils/currencyByType';
import { getMyTopUpsHistory } from '../../../../services/Wallets';
import { accountActions } from '../../../../store';
import TransactionHash from '../TransactionHash';
import { jsx as ___EmotionJSX } from "@emotion/react";
var topUpStatusMap = (_topUpStatusMap = {}, _defineProperty(_topUpStatusMap, topUpsStatus.Pending, {
  text: 'Pending',
  color: 'blue'
}), _defineProperty(_topUpStatusMap, topUpsStatus.Confirmed, {
  text: 'Confirmed',
  color: 'green'
}), _defineProperty(_topUpStatusMap, topUpsStatus.Completed, {
  text: 'Completed',
  color: 'green'
}), _defineProperty(_topUpStatusMap, topUpsStatus.Cancelled, {
  text: 'Cancelled',
  color: 'red'
}), _defineProperty(_topUpStatusMap, topUpsStatus.CouldNotComplete, {
  text: 'Could Not Complete',
  color: 'red'
}), _defineProperty(_topUpStatusMap, topUpsStatus.Refunded, {
  text: 'Refunded',
  color: 'orange'
}), _topUpStatusMap);
var TopUpsHistory = function TopUpsHistory(props) {
  var cashWallet = props.cashWallet,
    currentAccountId = props.currentAccountId;
  var tableRef = useRef();
  var columnsDefinition = [{
    name: 'Created Date',
    render: dateTime('createdOn')
  }, {
    name: 'Modified Date',
    render: dateTime('modifiedOn')
  }, {
    name: 'User Email',
    value: 'externalUser'
  }, {
    name: 'Amount',
    render: function render(row) {
      return currencyByType(row.amount, walletType.CASH);
    }
  }, {
    name: 'CP Hash',
    render: function render(row) {
      return ___EmotionJSX("div", {
        className: "top-up-table-details-column"
      }, row.externalTransactionId && ___EmotionJSX(TransactionHash, {
        transactionHash: row.externalTransactionId
      }));
    }
  }, {
    name: 'Status',
    render: function render(row) {
      var _topUpStatusMap$row$s, _topUpStatusMap$row$s2;
      return ___EmotionJSX(ColorBadge, {
        type: (_topUpStatusMap$row$s = topUpStatusMap[row.status]) === null || _topUpStatusMap$row$s === void 0 ? void 0 : _topUpStatusMap$row$s.color
      }, (_topUpStatusMap$row$s2 = topUpStatusMap[row.status]) === null || _topUpStatusMap$row$s2 === void 0 ? void 0 : _topUpStatusMap$row$s2.text);
    },
    width: '120px'
  }];
  return ___EmotionJSX("div", {
    className: "vk-wallets-balance-wraper"
  }, !!(cashWallet !== null && cashWallet !== void 0 && cashWallet.id) && ___EmotionJSX(Grid, {
    container: true,
    spacing: 5
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 12
  }, ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: "vk-wallets-balance-refresh-button",
    onClick: function onClick() {
      var _tableRef$current;
      (_tableRef$current = tableRef.current) === null || _tableRef$current === void 0 ? void 0 : _tableRef$current.getData();
    }
  }, ___EmotionJSX("i", {
    className: "la la-retweet"
  })), ___EmotionJSX("div", {
    className: "vk-paper-box"
  }, ___EmotionJSX(Table, {
    ref: tableRef,
    getDataKey: "items",
    getDataMethod: function getDataMethod(tableOptions) {
      return getMyTopUpsHistory(_objectSpread(_objectSpread({}, tableOptions), {}, {
        accountId: currentAccountId
      }));
    },
    columns: columnsDefinition,
    pageSize: 10,
    hasPagination: true,
    footerBar: TableFooterBar(),
    emptyMessage: "No Top Ups History to Show",
    updateOn: currentAccountId
  })))));
};
var mapProps = function mapProps(_ref) {
  var _accounts$current, _accounts$current2;
  var accounts = _ref.accounts;
  return {
    cashWallet: (accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.wallets.find(function (el) {
      return el.walletType.toLowerCase() === walletType.CASH;
    })) || {},
    currentAccountId: accounts === null || accounts === void 0 ? void 0 : (_accounts$current2 = accounts.current) === null || _accounts$current2 === void 0 ? void 0 : _accounts$current2.id
  };
};
export default connect(mapProps, accountActions)(TopUpsHistory);