function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _pick from "lodash/pick";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { add, sub } from 'exact-math';
import qs from 'query-string';
import { walletType, transactionType } from '../../enums/wallets';
import { startOfTheDay, endOfTheDay } from '../../utils/moment';
export var getTransactionsByWalletModel = function getTransactionsByWalletModel(data) {
  return (data === null || data === void 0 ? void 0 : data.transactions) && {
    transactions: data.transactions.map(function (el) {
      var _ref, _el$amount, _el$oldBalance, _el$amount2;
      return _objectSpread(_objectSpread({}, el), {}, {
        newBalance: el.type === transactionType.Outbound ? sub((_ref = (el === null || el === void 0 ? void 0 : el.oldBalance) < (el === null || el === void 0 ? void 0 : el.amount) ? el === null || el === void 0 ? void 0 : el.amount : el === null || el === void 0 ? void 0 : el.oldBalance) !== null && _ref !== void 0 ? _ref : 0, (_el$amount = el === null || el === void 0 ? void 0 : el.amount) !== null && _el$amount !== void 0 ? _el$amount : 0) : add((_el$oldBalance = el === null || el === void 0 ? void 0 : el.oldBalance) !== null && _el$oldBalance !== void 0 ? _el$oldBalance : 0, (_el$amount2 = el === null || el === void 0 ? void 0 : el.amount) !== null && _el$amount2 !== void 0 ? _el$amount2 : 0)
      });
    }),
    total: data.total
  };
};
export var getWalletForCurrentAccount = function getWalletForCurrentAccount(_ref2) {
  var _wallets$map;
  var wallets = _ref2.wallets,
    splitsBalances = _ref2.splitsBalances;
  var _ref3 = splitsBalances !== null && splitsBalances !== void 0 ? splitsBalances : {},
    splits = _ref3.availableSplits,
    maxSplits = _ref3.maxSplits;
  var availableSplits = maxSplits < splits ? maxSplits : splits;
  return (_wallets$map = wallets === null || wallets === void 0 ? void 0 : wallets.map(function (item) {
    var _item$walletType;
    var mappedItem = ((_item$walletType = item.walletType) === null || _item$walletType === void 0 ? void 0 : _item$walletType.toLowerCase()) === walletType.TOKEN ? _objectSpread(_objectSpread(_objectSpread({}, item), splitsBalances), {}, {
      availableSplits: availableSplits
    }) : _objectSpread({}, item);
    return _objectSpread({}, mappedItem);
  })) !== null && _wallets$map !== void 0 ? _wallets$map : [];
};
export var getAccumulatedTransactionsByWalletModel = function getAccumulatedTransactionsByWalletModel(response) {
  return (response === null || response === void 0 ? void 0 : response.items) && {
    items: response.items.map(function (el) {
      var _ref4, _el$amount3, _el$oldBalance2, _el$amount4;
      return _objectSpread(_objectSpread({}, el), {}, {
        newBalance: (el === null || el === void 0 ? void 0 : el.type) === transactionType.Outbound ? sub((_ref4 = (el === null || el === void 0 ? void 0 : el.oldBalance) < (el === null || el === void 0 ? void 0 : el.amount) ? el === null || el === void 0 ? void 0 : el.amount : el === null || el === void 0 ? void 0 : el.oldBalance) !== null && _ref4 !== void 0 ? _ref4 : 0, (_el$amount3 = el === null || el === void 0 ? void 0 : el.amount) !== null && _el$amount3 !== void 0 ? _el$amount3 : 0) : add((_el$oldBalance2 = el === null || el === void 0 ? void 0 : el.oldBalance) !== null && _el$oldBalance2 !== void 0 ? _el$oldBalance2 : 0, (_el$amount4 = el === null || el === void 0 ? void 0 : el.amount) !== null && _el$amount4 !== void 0 ? _el$amount4 : 0)
      });
    }),
    total: response.items.length
  };
};
export var getSafeDepositsParams = function getSafeDepositsParams(options) {
  var _ref5 = options !== null && options !== void 0 ? options : {},
    type = _ref5.type,
    startDate = _ref5.startDate,
    endDate = _ref5.endDate;
  var dates = {};
  if (startDate) dates.MatureDateFrom = startOfTheDay(startDate).format();
  if (endDate) dates.MatureDateTo = endOfTheDay(endDate).format();
  return qs.stringify(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), {}, {
    type: type,
    IsPaid: true
  }, dates), {
    arrayFormat: 'index'
  });
};
export var charityTopupModel = function charityTopupModel(options) {
  var donationAmount = options.donationAmount,
    walletType = options.walletType;
  return {
    items: [{
      donationAmount: donationAmount,
      walletType: walletType
    }]
  };
};