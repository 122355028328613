import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
/* eslint-disable prefer-const */
import React from 'react';
import { connect } from 'react-redux';
import Topbar from './Topbar';
import { LayoutContextConsumer } from '../LayoutContext';
import { checkStatus } from '../../../../utils/checkStatus';
import { walletType } from '../../../../enums/wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Header = function Header(props) {
  var user = props.user,
    hasAccounts = props.hasAccounts,
    cashWallet = props.cashWallet,
    isHeaderOpen = props.isHeaderOpen,
    menuConfig = props.menuConfig;
  var isActive = checkStatus(user === null || user === void 0 ? void 0 : user.status);
  return ___EmotionJSX("div", {
    className: "kt-header kt-grid__item ".concat(isHeaderOpen ? 'kt-header__topbar--mobile-on' : ''),
    id: "kt_header"
  }, ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX(LayoutContextConsumer, {
    menuConfig: menuConfig
  }, function (props) {
    var _props$subheader;
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h3", {
      className: "vk-subheader__title"
    }, isActive ? (_props$subheader = props.subheader) === null || _props$subheader === void 0 ? void 0 : _props$subheader.title : ''));
  }), ___EmotionJSX(Topbar, {
    isActive: isActive,
    hasAccounts: hasAccounts,
    cashWallet: cashWallet
  })));
};
var mapStateToProps = function mapStateToProps(store) {
  var _store$accounts, _store$accounts$items, _store$accounts2, _store$accounts2$curr;
  return {
    user: store === null || store === void 0 ? void 0 : store.user,
    hasAccounts: !!(store !== null && store !== void 0 && (_store$accounts = store.accounts) !== null && _store$accounts !== void 0 && (_store$accounts$items = _store$accounts.items) !== null && _store$accounts$items !== void 0 && _store$accounts$items.length),
    cashWallet: (store === null || store === void 0 ? void 0 : (_store$accounts2 = store.accounts) === null || _store$accounts2 === void 0 ? void 0 : (_store$accounts2$curr = _store$accounts2.current) === null || _store$accounts2$curr === void 0 ? void 0 : _store$accounts2$curr.wallets.find(function (el) {
      return (el.walletType || '').toLowerCase() === walletType.CASH;
    })) || {}
  };
};
export default connect(mapStateToProps)(Header);