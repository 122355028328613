import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _accountRankInformati, _accountStatusMap;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import sapphireIcon from '../assets/images/rank/sapphire.png';
import rubyIcon from '../assets/images/rank/ruby.png';
import emeraldIcon from '../assets/images/rank/emerald.png';
import diamondIcon from '../assets/images/rank/diamond.png';
import blueDiamondIcon from '../assets/images/rank/blueDiamond.png';
import blackDiamondIcon from '../assets/images/rank/blackDiamond.png';
import crownDiamondIcon from '../assets/images/rank/crownDiamond.png';
export var TreePlacement = {
  auto: 0,
  left: 1,
  right: 2
};
export var KYCType = {
  idProof: 'idProof',
  addressProof: 'addressProof'
};
export var KYCSubType = {
  idProof: {
    IDCard: 'card',
    Passport: 'passport',
    InternationalPassport: 'internationalPassport',
    DrivingLicense: 'driverLicence'
  },
  addressProof: {
    GasBill: 'gasBill',
    WaterBill: 'waterBill',
    ElectricityBill: 'electricityBill',
    BankStatement: 'bankStatement',
    MunicipalityGovStatement: 'municipalityGovStatement',
    ResidentialAgencyStatement: 'residentialAgencyStatement',
    NationalIdDocument: 'nationalIdDocument',
    RentalAgreement: 'rentalAgreement',
    PhoneBill: 'phoneBill'
  }
};
export var accountRank = {
  Rookie: '',
  Sapphire: 'Sapphire',
  Ruby: 'Ruby',
  Emerald: 'Emerald',
  Diamond: 'Diamond',
  BlueDiamond: 'Blue Diamond',
  BlackDiamond: 'Black Diamond',
  CrownDiamond: 'Crown Diamond'
};
export var accountRankInformation = (_accountRankInformati = {}, _defineProperty(_accountRankInformati, accountRank.Rookie, {
  rankName: 'Rookie',
  rankImage: null,
  nextRank: accountRank.Sapphire,
  nextRankReq: {
    activePackage: null,
    networkBV: null,
    weakerLegBV: null,
    requiredRank: null,
    totalRequiredRank: null,
    minAccWithRequiredRank: null
  }
}), _defineProperty(_accountRankInformati, accountRank.Sapphire, {
  rankName: 'Sapphire',
  rankImage: sapphireIcon,
  nextRank: accountRank.Ruby,
  nextRankReq: {
    activePackage: 'Trader',
    networkBV: 7000,
    weakerLegBV: null,
    requiredRank: null,
    totalRequiredRank: null,
    minAccWithRequiredRank: null
  }
}), _defineProperty(_accountRankInformati, accountRank.Ruby, {
  rankName: 'Ruby',
  rankImage: rubyIcon,
  nextRank: accountRank.Emerald,
  nextRankReq: {
    activePackage: 'Trader',
    networkBV: 40000,
    weakerLegBV: 10000,
    requiredRank: accountRank.Sapphire,
    totalRequiredRank: 2,
    minAccWithRequiredRank: 1
  }
}), _defineProperty(_accountRankInformati, accountRank.Emerald, {
  rankName: 'Emerald',
  rankImage: emeraldIcon,
  nextRank: accountRank.Diamond,
  nextRankReq: {
    activePackage: 'Tycoon',
    networkBV: 80000,
    weakerLegBV: 40000,
    requiredRank: accountRank.Ruby,
    totalRequiredRank: 3,
    minAccWithRequiredRank: 1
  }
}), _defineProperty(_accountRankInformati, accountRank.Diamond, {
  rankName: 'Diamond',
  rankImage: diamondIcon,
  nextRank: accountRank.BlueDiamond,
  nextRankReq: {
    activePackage: 'Tycoon',
    networkBV: 200000,
    weakerLegBV: 80000,
    requiredRank: accountRank.Emerald,
    totalRequiredRank: 3,
    minAccWithRequiredRank: 1
  }
}), _defineProperty(_accountRankInformati, accountRank.BlueDiamond, {
  rankName: 'Blue Diamond',
  rankImage: blueDiamondIcon,
  nextRank: accountRank.BlackDiamond,
  nextRankReq: {
    activePackage: 'Tycoon',
    networkBV: 500000,
    weakerLegBV: 200000,
    requiredRank: accountRank.Diamond,
    totalRequiredRank: 4,
    minAccWithRequiredRank: 2
  }
}), _defineProperty(_accountRankInformati, accountRank.BlackDiamond, {
  rankName: 'Black Diamond',
  rankImage: blackDiamondIcon,
  nextRank: accountRank.CrownDiamond,
  nextRankReq: {
    activePackage: 'Tycoon',
    networkBV: 1500000,
    weakerLegBV: 500000,
    requiredRank: accountRank.BlueDiamond,
    totalRequiredRank: 5,
    minAccWithRequiredRank: 2
  }
}), _defineProperty(_accountRankInformati, accountRank.CrownDiamond, {
  rankName: 'Crown Diamond',
  rankImage: crownDiamondIcon,
  nextRank: accountRank.CrownDiamond,
  nextRankReq: {
    activePackage: 'Tycoon',
    networkBV: 8000000,
    weakerLegBV: 1500000,
    requiredRank: accountRank.BlackDiamond,
    totalRequiredRank: 6,
    minAccWithRequiredRank: 3
  }
}), _accountRankInformati);
export var accountRankId = {
  0: accountRank.Rookie,
  1: accountRank.Sapphire,
  2: accountRank.Ruby,
  3: accountRank.Emerald,
  4: accountRank.Diamond,
  5: accountRank.BlueDiamond,
  6: accountRank.BlackDiamond,
  7: accountRank.CrownDiamond
};
export var AccountStatus = {
  Active: 'Active',
  Inactive: 'Inactive',
  Frozen: 'Frozen'
};
export var accountStatusMap = (_accountStatusMap = {}, _defineProperty(_accountStatusMap, AccountStatus.Active, {
  text: 'Active',
  color: 'green'
}), _defineProperty(_accountStatusMap, AccountStatus.Inactive, {
  text: 'Inactive',
  color: 'blue'
}), _defineProperty(_accountStatusMap, AccountStatus.Frozen, {
  text: 'Frozen',
  color: 'orange'
}), _accountStatusMap);